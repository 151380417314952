var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLREMOVE", icon: "remove" },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLSAVE", icon: "save" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "true",
                            type: "edit",
                            name: "col0",
                          },
                          model: {
                            value: _vm.data.col0,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col0", $$v)
                            },
                            expression: "data.col0",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.bItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "col6",
                            label: "배출구",
                          },
                          model: {
                            value: _vm.data.col6,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col6", $$v)
                            },
                            expression: "data.col6",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "측정일", name: "col1" },
                          model: {
                            value: _vm.data.col1,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col1", $$v)
                            },
                            expression: "data.col1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.whetherItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "col4",
                            label: "날씨",
                          },
                          model: {
                            value: _vm.data.col4,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col4", $$v)
                            },
                            expression: "data.col4",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "온도",
                            suffix: "℃",
                            name: "col5",
                          },
                          model: {
                            value: _vm.data.col5,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col5", $$v)
                            },
                            expression: "data.col5",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "습도",
                            suffix: "(%)",
                            name: "col11",
                          },
                          model: {
                            value: _vm.data.col11,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col11", $$v)
                            },
                            expression: "data.col11",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "기압",
                            suffix: "(mb)",
                            name: "col12",
                          },
                          model: {
                            value: _vm.data.col12,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col12", $$v)
                            },
                            expression: "data.col12",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "풍향",
                            suffix: "(풍)",
                            name: "col13",
                          },
                          model: {
                            value: _vm.data.col13,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col13", $$v)
                            },
                            expression: "data.col13",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "풍속",
                            suffix: "(m/sec)",
                            name: "col15",
                          },
                          model: {
                            value: _vm.data.col15,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col15", $$v)
                            },
                            expression: "data.col15",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "가스속도",
                            suffix: "(m/s)",
                            name: "col16",
                          },
                          model: {
                            value: _vm.data.col16,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col16", $$v)
                            },
                            expression: "data.col16",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "가스온도",
                            suffix: "(℃)",
                            name: "col17",
                          },
                          model: {
                            value: _vm.data.col17,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col17", $$v)
                            },
                            expression: "data.col17",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "수분함량",
                            suffix: "(%)",
                            name: "col18",
                          },
                          model: {
                            value: _vm.data.col18,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col18", $$v)
                            },
                            expression: "data.col18",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "실측산소농도",
                            suffix: "(%)",
                            name: "col19",
                          },
                          model: {
                            value: _vm.data.col19,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col19", $$v)
                            },
                            expression: "data.col19",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "표준산소농도",
                            suffix: "(%)",
                            name: "col20",
                          },
                          model: {
                            value: _vm.data.col20,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col20", $$v)
                            },
                            expression: "data.col20",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "유량",
                            suffix: "(S㎥/일)",
                            name: "col21",
                          },
                          model: {
                            value: _vm.data.col21,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col21", $$v)
                            },
                            expression: "data.col21",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "자가측정방법",
                            name: "col22",
                          },
                          model: {
                            value: _vm.data.col22,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col22", $$v)
                            },
                            expression: "data.col22",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "환경기술인",
                            suffix: "",
                            name: "col14",
                          },
                          model: {
                            value: _vm.data.col14,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col14", $$v)
                            },
                            expression: "data.col14",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "환경기술인 의견",
                            name: "col23",
                          },
                          model: {
                            value: _vm.data.col23,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col23", $$v)
                            },
                            expression: "data.col23",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-table", {
              ref: "grid",
              attrs: {
                title: "배출구별 검사결과",
                tableId: "grid",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                usePaging: false,
                hideBottom: true,
                columnSetting: false,
                isFullScreen: false,
                filtering: false,
                isExcelDown: false,
                gridHeight: "280px",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }