<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  label="LBLREMOVE"
                  icon="remove" />
                <c-btn
                  v-if="editable"
                  label="LBLSAVE"
                  icon="save" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-plant required="true" type="edit" name="col0" v-model="data.col0" />
              </div>
              <div class="col-3">
                <c-select
                  :comboItems="bItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="col6"
                  label="배출구"
                  v-model="data.col6"
                ></c-select>
              </div>
              <div class="col-2">
                <c-datepicker
                  label="측정일"
                  name="col1"
                  v-model="data.col1"
                />
              </div>
              <div class="col-2">
                <c-select
                  :comboItems="whetherItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="col4"
                  label="날씨"
                  v-model="data.col4"
                ></c-select>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable"
                  label="온도"
                  suffix="℃"
                  name="col5"
                  v-model="data.col5">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="습도"
                  suffix="(%)"
                  name="col11"
                  v-model="data.col11">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="기압"
                  suffix="(mb)"
                  name="col12"
                  v-model="data.col12">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="풍향"
                  suffix="(풍)"
                  name="col13"
                  v-model="data.col13">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="풍속"
                  suffix="(m/sec)"
                  name="col15"
                  v-model="data.col15">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="가스속도"
                  suffix="(m/s)"
                  name="col16"
                  v-model="data.col16">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="가스온도"
                  suffix="(℃)"
                  name="col17"
                  v-model="data.col17">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="수분함량"
                  suffix="(%)"
                  name="col18"
                  v-model="data.col18">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="실측산소농도"
                  suffix="(%)"
                  name="col19"
                  v-model="data.col19">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="표준산소농도"
                  suffix="(%)"
                  name="col20"
                  v-model="data.col20">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="유량"
                  suffix="(S㎥/일)"
                  name="col21"
                  v-model="data.col21">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="자가측정방법"
                  name="col22"
                  v-model="data.col22">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="환경기술인"
                  suffix=""
                  name="col14"
                  v-model="data.col14">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="환경기술인 의견"
                  name="col23"
                  v-model="data.col23">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid"
          title="배출구별 검사결과"
          tableId="grid"
          :columns="grid.columns"
          :data="grid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="280px"
        >
        </c-table>
      </div>
    </q-form>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'self-air-measure-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
          psiDocumentId: '',
          documentClassCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      whetherItems: [
        { code: '1', codeName: '맑음' },
        { code: '2', codeName: '흐림' },
        { code: '3', codeName: '눈' },
        { code: '4', codeName: '비' },
        { code: '5', codeName: '구름' },
      ],
      bItems: [
        { code: '1', codeName: '배출구1' },
        { code: '2', codeName: '배출구2' },
        { code: '3', codeName: '배출구3' },
        { code: '4', codeName: '배출구4' },
        { code: '5', codeName: '배출구5' },
      ],
      data: {
        col0: '',
        col1: '2021-01-05',
        col2: 'D10003',
        col3: 'U000000018',
        col4: '1',
        col5: '15',
        col6: '1',
        col7: '',
        col11: '',
        col12: '',
        col13: '',
        col14: '',
        col15: '',
        col16: '',
        col17: '',
        col18: '',
        col19: '',
        col20: '',
        col21: '대행의뢰',
        col22: '',
      },
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '검사항목',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: 'LBLUNIT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '법적기준',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '자체기준',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col14',
            field: 'col14',
            label: '측정농도',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col15',
            field: 'col15',
            label: '일일배출량(kg/일)',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '검사기기',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '검사방법',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
      this.grid.data = [
        {
          col1: '먼지',
          col2: 'mg/Sm3',
          col3: '30',
          col4: '',
          col5: '시료채취기',
          col6: '원통여지(중량법)',
          col7: '1',
          col14: '',
          col15: '',
        },
        {
          col1: '황산화물',
          col2: 'ppm',
          col3: '200',
          col4: '',
          col5: '시료채취기',
          col6: '전기화학식(정전위전해법)',
          col7: '2',
          col14: '',
          col15: '',
        },
      ]
    },
  }
};
</script>